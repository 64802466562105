'use client'

import { forwardRef } from 'react'
import { useRouter } from 'next/navigation'
import { Slot } from '@radix-ui/react-slot'
import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'
import { cn } from '@/utils/cn'

const buttonVariants = cva(
  'focus-visible:ring-ring inline-flex items-center justify-start whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'text-base-white bg-blue-500 shadow hover:bg-blue-400',
        destructive:
          'hover:bg-destructive/90 bg-destructive text-destructive-foreground shadow-sm',
        outline:
          'border-input bg-background hover:bg-accent hover:text-accent-foreground border shadow-sm',
        secondary:
          'hover:bg-secondary/80 bg-secondary text-secondary-foreground shadow-sm',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        calendar: '',
        link: 'text-primary underline-offset-4 hover:underline',
        selected: 'bg-sky-800/50 border border-sky-500',
      },
      size: {
        default: 'h-9 px-4 py-2',
        sm: 'h-8 rounded-md px-3 text-xs',
        lg: 'h-10 rounded-md px-8',
        icon: 'size-9',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

// Define specific types for variant and size
type ButtonVariant =
  | 'default'
  | 'destructive'
  | 'outline'
  | 'secondary'
  | 'ghost'
  | 'calendar'
  | 'link'
  | 'selected'
type ButtonSize = 'default' | 'sm' | 'lg' | 'icon'

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  variant?: ButtonVariant
  size?: ButtonSize
  route?: string
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  {
    className,
    variant = 'default',
    size = 'default',
    asChild = false,
    children,
    onClick,
    ...props
  },
  ref,
) {
  const Comp = asChild ? Slot : 'button'

  return (
    <Comp
      type={asChild ? undefined : 'button'}
      className={cn(buttonVariants({ variant, size }), className)}
      ref={ref}
      onClick={onClick}
      {...props}
    >
      {/* @ts-ignore */}
      {children}
    </Comp>
  )
})

export { Button, buttonVariants }
